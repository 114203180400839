import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";
import rating from "../../../assets/img/rating.png"
import game from "../../../assets/img/game.jpg"

  const Game = React.memo(function MyComponent() {
  return (
    <section className={styles.section} >
      <div style={{zIndex:3, height: '100%'}} className="wrap">
        <div className={styles.box}>
          <div className={styles.title}>
            <Headline tagtype='h2'><span>Vinyls</span> are an important <br /> asset in our future <br /> play-to-earn game</Headline>
          </div>
          <div className={styles.ratebox}>
          <img className={styles.rating} src={rating} alt="" />
          <img className={styles.gameimg} src={game} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
});


export default Game;
