import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import osimg from "../../../assets/img/oschallenge.jpg";

const MintWbmcByIdForm = ({tokenId, LinkTw, LinkDs, userAddress, SimpleContract, AppSalesLimit,  SystemWbmcContract}) => {
    const [chainId, setChainId] = useState('');
    const [isMetaMask, setIsMetaMask] = useState(false);
    const [transactionState, setTransactionState] = useState(0);
    const [totalSupply, setTotalSupply] = useState(0);
    const [tokenOwner, setTokenOwner] = useState('');
    const [tokenState, setTokenState] = useState(false);
    const [osChallenge, setOsChallenge] = useState(false);
    const [minted, setMinted] = useState(false);

    useEffect(() => {
        getChainId();
        getTotalSupply();
        getTokenOwner();
        getTokenState();
    });

    const getChainId = async (e) => {
        if(SimpleContract.givenProvider){
            setChainId(SimpleContract.givenProvider.chainId);
            setIsMetaMask(SimpleContract.givenProvider.isMetaMask);
        }
    }

    const getTotalSupply = async (e) => {
        if(SimpleContract.givenProvider){
            const resultTotalSupply = await SimpleContract.methods.totalSupply().call();
            if(resultTotalSupply){
                setTotalSupply(resultTotalSupply);
            }
        }
    }

    const getTokenOwner = async (e) => {
        if(SystemWbmcContract.givenProvider){
            const resultTokenOwner = await SystemWbmcContract.methods.ownerOf(tokenId).call();
            if(resultTokenOwner){
                setTokenOwner(resultTokenOwner);
            }
        } else {
            setTokenOwner('');
        }
    }

    const getTokenState = async (e) => {
        if(SimpleContract.givenProvider){
            const resultTokenState = await SimpleContract.methods.isClaimed(tokenId).call();
            if(resultTokenState){
                setTokenState(resultTokenState);
            }
        }
    }

    const closOsch = () => {
        setOsChallenge(false);
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(transactionState === 0 || transactionState === 1){
            orderToken();
        }
    }

    const orderToken = async(e) => {
        setMinted(false);
        setTransactionState(0);
            if(SimpleContract.givenProvider){
                if(totalSupply < AppSalesLimit){
                    if(userAddress !== 0){
                        if(tokenOwner == userAddress){
                            if(tokenState){

                            } else {
                                setTransactionState(4);
                                await SimpleContract.methods.claimByWannabeId(tokenId).send({ from: userAddress })
                                .on('transactionHash', function(hash){
                                    console.log('transactionHash');
                                    setTransactionState(2);
                                    setOsChallenge(true);
                                })
                                .on("error", function(error) {
                                    //console.log('error');
                                    console.log(error.message);
                                    setTransactionState(1);
                                })
                                .on("receipt", function(receipt) {
                                    console.log('Mint done!');
                                    setMinted(true);
                                    //window.location.replace("https://vinyl.wannabesmusic.club/wallet");
                                    //Redirect to Wallet
                                    //console.log(receipt);
                                });   
                            }
                        } 
                    }
                }
            }
       
    }

    if(totalSupply < AppSalesLimit){
        return (
            <>           
            <div className={styles.icb_container_zoom}>
                {(osChallenge)?(
                    <div className={styles.oschallenge_container}>
                        <div className={styles.oschallenge_close} onClick={closOsch}>X</div>
                        <div className={styles.oschallenge_title}>Don't list your VINYL for cheap!</div>
                        <div className={styles.oschallenge_subtitle}>Take part in our OS Price challenge instead and win even more ETH!</div>
                        <img src={osimg} alt="OS Challenge" className={styles.oschallenge_poster} />
                        <div className={styles.oschallenge_text}>The higher VINYL floor is by the time the public sale starts, the more successful will be VINYL and WBMC2.0 community and the more funds we have for our WBMC 2.0 DAO strategy!</div>
                    </div>
                ):""}



                {(isMetaMask && chainId !== '0x1') ? (<div className={styles.metamask_msg_error}>You should switch to Ethereum MainNet in Metamask!</div>) : 
                <>


                {(tokenOwner !== 0) ? (<div className={styles.icb_container_buy_address}>Wannabe's owner: {tokenOwner}</div>) : "" }

                {(userAddress !== 0) ? (<div className={styles.icb_container_buy_address}>Your address: {userAddress}</div>) : "" }
 
                {(minted) ? (<div className={styles.metamask_msg_success}>Your vinyl is minted! Check your wallet</div>) : ""}

                {(tokenState)?(<p style={{color: 'red'}}>Vinyl has already been minted for this token</p>):(
                    
                        (tokenOwner == userAddress)?(
                        <>
                            <form onSubmit={handleSubmit} className={styles.metamask_buy_form}>
                            {(transactionState === 2 || transactionState === 4)?(
                                <input type="submit" value="Mint" className={styles.icb_container_button_disable} style={{fontSize: "1.1em"}}/>
                            ) : (
                                <input type="submit"  value="Mint" className={styles.icb_container_button} style={{fontSize: "1.1em"}} />
                            )}
                            </form>

                            {(transactionState === 1) ? (<div className={styles.metamask_msg_error}>MetaMask: User denied transaction signature</div>) : ""}
                            
                            {(transactionState === 2) ? (<div className={styles.metamask_msg_success}>Transaction has been successfully sent to the blockchain.<br />1 token gonna appear in your wallet soon!</div>) : ""}
                            
                            {(transactionState === 4) ? (<div className={styles.metamask_msg_success}>You need to CONFIRM the transaction in your METAMASK wallet!</div>) : ""}

                            
                        </>
                        ):(<p>You are not the owner of this Wannabe</p>)  
                )}


                
                </>
                }
                

                
            </div>

            <div className={styles.info_more_mint}><p>If you need any help with minting please contact us<br /><a href={LinkTw} title="Twitter" target="_blank" rel="noreferrer nofollow noopener">Twitter</a>, <a href={LinkDs} title="Discord" target="_blank" rel="noreferrer nofollow noopener">Discord</a></p></div>
            </>
        )
    } else {
        return (
            <h2>Sold out!</h2>
        )
    }
}

export default MintWbmcByIdForm
