import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ListWbmcTokens from './ListWbmcTokens';
import ButtonClaimAll from './ButtonClaimAll';
import styles from './style.module.scss';

const MintWbmcForm = ({LinkTw, LinkDs, SimpleContract, userAddress, AppSalesLimit, WhitelistOnly, OwnerContract, systemWbmcTotalSupply}) => {
    const [chainId, setChainId] = useState('');
    const [isMetaMask, setIsMetaMask] = useState(false);
    const [totalSupply, setTotalSupply] = useState(0);

    // From Owner Contract
    const [ownerTokens, setOwnerTokens] = useState([]);

    useEffect(() => {
        getChainId();
    });

    useEffect(() => {
        getOwnerTokens();
        //console.log(ownerTokens);
    });

    //,[OwnerContract]

    const getChainId = async (e) => {
        if(SimpleContract.givenProvider){
            setChainId(SimpleContract.givenProvider.chainId);
            setIsMetaMask(SimpleContract.givenProvider.isMetaMask);
        }
    }

    // From Owner Contract
    const getOwnerTokens = async (e) => {
        if(OwnerContract.givenProvider){
            const resultOwnerTokens = await OwnerContract.methods.tokensOfOwner(userAddress, systemWbmcTotalSupply).call();
            if(resultOwnerTokens){
                //console.log(resultOwnerTokens);
                setOwnerTokens(resultOwnerTokens);
            }
        }
    }
    
    if(totalSupply < AppSalesLimit){
        return (
            <>
            <div className={styles.icb_container_zoom}>


                {(isMetaMask && chainId !== '0x1') ? (<div className={styles.metamask_msg_error}>You should switch to Ethereum MainNet in Metamask!</div>) : 
                <>


                {(ownerTokens.length > 0)?(
                    <>
                        <ListWbmcTokens ListItems={ownerTokens} SimpleContract={SimpleContract} />
                        {/* <ButtonClaimAll ListItems={ownerTokens} SimpleContract={SimpleContract} totalSupply={totalSupply} AppSalesLimit={AppSalesLimit} userAddress={userAddress} /> */}
                    </>
                ):(<><h3>Your don't have WBMC tokens!</h3></>)}


                </>
                }


                {(userAddress !== 0) ? (<div className={styles.icb_container_buy_address}>Your address: {userAddress}</div>) : "" }
            </div>

            <p>You can <Link to='/check-wbmc' title="check Wannabe" >check Wannabe</Link> before buy.<br />Check if Vinyl has been minted for this Wannabe's ID.</p>

            <div className={styles.info_more_mint}><p>If you need any help with minting please contact us<br /><a href={LinkTw} title="Twitter" target="_blank" rel="noreferrer nofollow noopener">Twitter</a>, <a href={LinkDs} title="Discord" target="_blank" rel="noreferrer nofollow noopener">Discord</a></p></div>

            {(WhitelistOnly)?(<>
            <div className={styles.info_more_mint}>
                <h4>If you have WBMC tokens:</h4>
                <p style={{marginTop: 0}}>
                <Link to={'/mint-vibe'} title='Mint for Vibe' className={styles.vinyl_button}>Purchase VINYL with $VIBE</Link>
                </p>

                <h4>If you don't have WBMC tokens:</h4>
                <p style={{marginTop: 0}}>
                <Link to={'/mint-eth'} title='Minting with ETH' className={styles.vinyl_button}>Minting with ETH{(WhitelistOnly)?(<>: Only Whitelisted users</>):""}</Link>
                </p>
            </div>
            </>):""}

            </>
        )
    } else {
        return (
            <h2>Sold out!</h2>
        )
    }
}

export default MintWbmcForm
