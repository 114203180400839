import styles from './style.module.scss';
import metamask from "../../../assets/img/metamask-logo.png";

const MInstall = () => {
    return (
        <div className={styles.icb_container}>
            <p>To get Vinyl you need to install Metamask wallet!</p>
            
            <a href="https://metamask.io/" target="_blank" rel="nofollow noopener noreferrer" title="Install Metamask" className={styles.icb_container_button}>Install Metamask</a>

            <img src={metamask} alt="Install Metamask" title="Install Metamask" className={styles.icb_container_logo} />
        </div>
    )
}

export default MInstall
