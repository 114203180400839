import { useState, useEffect} from 'react';
import TokensList from '../tokenslist/Tokenslist';
import styles from './style.module.scss';

const WalletForm = ({SimpleContract, userAddress}) => {
    // From Main Contract
    const [chainId, setChainId] = useState('');
    const [isMetaMask, setIsMetaMask] = useState(false);
    const [userTokensCount, setUserTokensCount] = useState(0);
    const [userTokens, setUserTokens] = useState([]);
    
    const TokensListItemAlt = 'Vinyl';
    const TokensListWithLink = false; 

    useEffect(() => {
        getChainId();
        getUserTokensCount();
        getUserTokens();
    },[]);

    const getChainId = async (e) => {
        if(SimpleContract.givenProvider){
            setChainId(SimpleContract.givenProvider.chainId);
            setIsMetaMask(SimpleContract.givenProvider.isMetaMask);
        }
    }

    const getUserTokensCount = async (e) => {
        if(SimpleContract.givenProvider){
            const resultUserTokensCount = await SimpleContract.methods.balanceOf(userAddress).call();
            if(resultUserTokensCount){
                setUserTokensCount(resultUserTokensCount);
                console.log(resultUserTokensCount);
            }
        }
    }

    const getUserTokens = async (e) => {
        if(SimpleContract.givenProvider){
            const resultUserTokens = await SimpleContract.methods.tokensOfOwner(userAddress).call();
            if(resultUserTokens){
                setUserTokens(resultUserTokens);
                console.log(resultUserTokens);
            }
        }
    }

    return (
        <div>
            {(isMetaMask && chainId !== '0x1') ? (<div className={styles.metamask_msg_error}>You should switch to Ethereum MainNet in Metamask!</div>) : 
            <>

            <div className={styles.icb_container_wallet_address}>Your address: <span>{userAddress}</span></div>

            <div className={styles.icb_container_wallet_counter}>You have: <span>{userTokensCount}</span>{(userTokensCount > 1)?' vinyls':' vinyl'}  </div>

            {(userTokens.length > 0)?(<><h3 className="wallet-subtitle">Your collection:</h3><TokensList ListItems={userTokens} ListItemAlt={TokensListItemAlt} ListWithLink={TokensListWithLink} /></>):""}



            </>
                }
        </div>
    )
}

export default WalletForm
