import styles from './style.module.scss';
import { Fade } from 'react-reveal';

const Headline = (props) => {
	switch (props.tagtype) {
		case 'h1':
			return <Fade top><h1 className={styles.box}>{props.children}</h1></Fade>;
		case 'h2':
			return <Fade top><h2 className={styles.box}>{props.children}</h2></Fade>;
		case 'h3':
			return <Fade top><h3 className={styles.boxsmall}>{props.children}</h3></Fade>;
		default:
			return <Fade top><h2 className={styles.box}>{props.children}</h2></Fade>;
	}
}

Headline.defaultProps = {
	tagtype: "h2",
	style: null
}

export default Headline;
