import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import './Mint.css';

const Mint = ({setCurrentPage, AppName, AppUrl, LinkOs, salesLaunched, WhitelistOnly, systemTotalSupply, AppSalesLimit}) => {
    useEffect(() => {
		setCurrentPage('mint');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{'Mint - ' + AppName}</title>
                <meta name="description" content={'Mint - ' + AppName} />
                <meta name="keywords" content={'Mint - ' + AppName} />
                <meta property="og:title" content={'Mint - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/mint'} />
                <meta property="og:description" content={'Mint - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/mint'} />
            </Helmet>

            <div className="page-content">
                <div className="page-content-container">
                    <h1>Mint Vinyl</h1>
                    
                    {(salesLaunched) ? ((systemTotalSupply < AppSalesLimit) ? (
                            <>
                            {(WhitelistOnly)?(
                                <>
                                    <h3>Private sale</h3>
                                    {/*
                                    <div className="token-minted-counter">
                                        Minted: <span>{systemTotalSupply}</span>
                                    </div>
                                    */}

                                    <div className="buy-button-container">
                                        <h4 className="buy-button-container-title">
                                            If you have WBMC tokens:
                                        </h4>
                                        <Link to={'/mint-wbmc'} title='Mint Wannabes' className="vinyl_button">Free VINYL Claiming for Wannabes Holders</Link>
                                        <Link to={'/mint-vibe'} title='Mint Vibe' className="vinyl_button">Purchase VINYL with $VIBE</Link>
                                        <h4 className="buy-button-container-title">
                                            If you don't have WBMC tokens:
                                        </h4>
                                        <Link to={'/mint-eth'} title='Mint' className="vinyl_button">Minting with ETH: Only Whitelisted users</Link>
                                    </div>
                                </>
                            ):(
                                <>
                                    {/*
                                    <div className="token-minted-counter">
                                        Minted: <span>{systemTotalSupply}</span>
                                    </div>
                                    */}

                                    <div className="buy-button-container">
                                        <Link to={'/mint-eth'} title='Mint' className="vinyl_button">Minting with ETH</Link>
                                    </div>
                                </>
                            )}
                            </>
                            ): (
                            <>
                                <div className="token-minted-counter">
                                    Minted: <span>{AppSalesLimit}</span>
                                    <p><b>Sold out! You can get Vinyl on <a href={LinkOs} title="OpenSea" target="_blank" rel="noreferrer nofollow noopener">OpenSea</a></b><br /><br /></p>
                                </div>
                            </>
                        )) : (<h2>Minting have not started yet!</h2>) }
                </div>
            </div>
        </>
    )
}

export default Mint
