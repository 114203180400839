import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";
import holder from "../../../assets/img/holder.png"
import { Link } from 'react-router-dom';


const Holder = React.memo(function MyComponent({salesLaunched, WhitelistOnly}) {
  return (
    <section className={styles.section} >
      <div style={{zIndex:3, height: '100%'}} className="wrap">
        <div className={styles.box}>
          <div className={styles.title}>
            <Headline tagtype='h2'>If you are a Wannabe <br /> holder, you can <br />claim <span>Vinyl</span> for free!<br />
                {(salesLaunched)?(<>
                    {(WhitelistOnly)?(<><Link to='/mint' title="Mint" className={styles.mint_button}>Mint</Link></>):(<><Link to='/mint-eth' title="Mint" className={styles.mint_button}>Mint</Link></>)}
                </>):""}
            </Headline>
          </div>
          <div className={styles.content}>
          <img src={holder} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
});


export default Holder;
