import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

const ListWbmcTokens = ({ ListItems, SimpleContract }) => {
    // const [totalTokens, setTotalTokens] = useState(0);
    // const [unclaimedTokens, setUnclaimedTokens] = useState(0);


    const listContent = ListItems.map((ListItem, index) => {
        if(ListItem !== '0'){
            const [tokenState, setTokenState] = useState(false);

            useEffect(() => {
                getTokenState();
            });

            const getTokenState = async (e) => {
                if(SimpleContract.givenProvider){
                    const resultTokenState = await SimpleContract.methods.isClaimed(ListItem).call();
                    if(resultTokenState){
                        setTokenState(resultTokenState);
                    }
                }
            }

            return (
                <>  
                <div key={index} className={styles.wbmc_list_item}>Wannabe {ListItem} - {(tokenState)?'already claimed':(<><Link to={'/mint-from-wannabe/'+ListItem} title='Claim Vinyl'>claim Vinyl</Link></>)}</div>
                </>
            )
        }
    });

    
    return (
        <>
            <h4>You have:</h4>
            <div className={styles.list_wbmc_tokens}>
                {listContent}
            </div>
        </>
    )
}

export default ListWbmcTokens
