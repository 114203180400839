import React, { useRef, useEffect, useState } from 'react';
import { useIsVisible } from 'react-is-visible';
import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";
import videobig from "../../../assets/video/creation.mp4";
import videosmall from "../../../assets/video/creation-small.mp4";

  const Creation = React.memo(function MyComponent() {

  const nodeRef = useRef()
  const isVisible = useIsVisible(nodeRef)

  const [videoSrc, setVideoSrc] = useState(videosmall);


  useEffect(() => {

    var video = document.getElementById("myVideo");
    
    if(window.innerWidth > 1200) {
      setVideoSrc(videobig);
    }

    if (isVisible) {
      video.play();
    } else {
      video.pause();
    }

  }, [isVisible]);


 

  return (
    <section ref={nodeRef} className={styles.section} >
      <video playsInline  muted loop preload="none" id="myVideo">
        <source src={videoSrc} type="video/mp4" />
      </video>
      <div style={{ zIndex: 3 }} className="wrap">
        <div className={styles.title}>
          <Headline tagtype='h2'>Each <span>Vinyl NFT</span> features amazing music made with an innovative generation script</Headline>
        </div>
      </div>
    </section>
  )
});


export default Creation;
