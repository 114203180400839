import {useState} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if(!firstName){
            setErrorMsg("Enter your name");
        } else {
            if(!email){
                setErrorMsg("Enter your email");
            } else {  
                if(email.indexOf("@") > -1){
                    setErrorMsg("");

                    email &&
                    firstName &&
                    email.indexOf("@") > -1 &&
                    onValidated({
                        EMAIL: email,
                        MERGE1: firstName,
                    });
                } else {
                    setErrorMsg("Invalid email");
                }
            }
        }
    }

    if(status === "success"){
        return (
            <div
                className={styles.mail_form_done_smg}
                dangerouslySetInnerHTML={{ __html: message }}
            />
        );
    } else {
        return (
            <form className={styles.mail_form} onSubmit={(e) => handleSubmit(e)} id="mail-form">
            <div className={styles.mail_form_title}>Join our email list for future updates</div>
            <div className={styles.mail_form_validation_smg}>{errorMsg}</div>
            {status === "sending" && (
            <div className={styles.mail_form_done_smg}>
                Sending...
            </div>
            )}

            {status === "error" && (
            <div 
                className={styles.mail_form_error_smg}
                dangerouslySetInnerHTML={{ __html: message }}
            />
            )}
            
            <input type="text" name="name" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="Name" className={styles.subscribeInput} />
            <input type="text" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email Address" className={styles.subscribeInput} />
            <input type="submit" value="Subscribe" className={styles.subscribeButton} />
        </form>
        );
    }
};

const MailForm = () => {
    const postUrl = `https://gmail.us6.list-manage.com/subscribe/post?u=d6246169e61bdf40bec849174&id=eedc47f02d`;

    return (
        <section id='mailform' className={styles.section} >
            <div style={{ zIndex: 3, height: '100%' }} className="wrap">
                <div className={styles.title}>
                    <Headline tagtype='h2'>GET ON THE LIST</Headline>
                </div>

                <div className={styles.mailbox}>
                    <MailchimpSubscribe 
                        url={postUrl}
                        render={({ subscribe, status, message }) => (
                            <CustomForm
                                status={status} 
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        )}
                    />
                </div>
            </div>
        </section>
    )
}

export default MailForm
