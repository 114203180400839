import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import osimg from "../../../assets/img/oschallenge.jpg";

const MintVibeForm = ({LinkTw, LinkDs, SimpleContract, userAddress, AppSalesLimit, VibeContract, WhitelistOnly}) => {
    const [tokenPrice, setTokenPrice] = useState(5000);
    const [chainId, setChainId] = useState('');
    const [isMetaMask, setIsMetaMask] = useState(false);
    const [transactionState, setTransactionState] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [gasPrice, setGasPrice] = useState(50);
    const [totalSupply, setTotalSupply] = useState(0);
    const [osChallenge, setOsChallenge] = useState(false);
    const [minted, setMinted] = useState(false);
    const rate = 1000000000000000000;
    //const tokenVibePrice = '5000000000000000000000';

    useEffect(() => {
        getChainId();
        getVibeBalance();
        getGasPrice();
        getTotalSupply();
        getRightBalance();
    });

    const getChainId = async (e) => {
        if(SimpleContract.givenProvider){
            setChainId(SimpleContract.givenProvider.chainId);
            setIsMetaMask(SimpleContract.givenProvider.isMetaMask);
        }
    }

    const getTotalSupply = async (e) => {
        if(SimpleContract.givenProvider){
            const resultTotalSupply = await SimpleContract.methods.totalSupply().call();
            if(resultTotalSupply){
                setTotalSupply(resultTotalSupply);
            }
        }
    }

    const getVibeBalance = async (e) => {
        if(VibeContract.givenProvider){
            const resultUserVibeTokens = await VibeContract.methods.balanceOf(userAddress).call();
            if(resultUserVibeTokens){
                //console.log(resultUserVibeTokens);
                setUserBalance(resultUserVibeTokens);
            }
        }
    }

    const getGasPrice = () => {
        fetch('https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=83NNEWF158AJ695GKEEA7HSKNGNZIGQHM6')
			  .then((response) => {
			    return response.json();
			  })
			  .then((data) => {
			    setGasPrice(data.result.ProposeGasPrice);
			});
    }

    const getRightBalance = async (e) => {
        if(transactionState === 1 || transactionState === 2 || transactionState === 4){
        
        } else {
            if(tokenPrice <= userBalance/rate){
                setTransactionState(0);
            } else {
                setTransactionState(3);
            }
        }
    }

    const closOsch = () => {
        setOsChallenge(false);
    }
    
    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(transactionState === 0 || transactionState === 1){
            orderToken();
        }
    }

    const orderToken = async(e) => {
        setMinted(false);
        setTransactionState(0);
            if(SimpleContract.givenProvider){
                if(totalSupply < AppSalesLimit){
                    if(userAddress !== 0){
                            if(tokenPrice > 0){
                                setTransactionState(4);
                                await SimpleContract.methods.claimVibe().send({ from: userAddress })
                                .on('transactionHash', function(hash){
                                    console.log('transactionHash');
                                    setTransactionState(2);
                                    setOsChallenge(true);
                                })
                                .on("error", function(error) {
                                    //console.log('error');
                                    console.log(error.message);
                                    setTransactionState(1);
                                })
                                .on("receipt", function(receipt) {
                                    console.log('Mint done!');
                                    setMinted(true);
                                    setTransactionState(0);
                                    //window.location.replace("https://vinyl.wannabesmusic.club/wallet");
                                    //Redirect to Wallet
                                    //console.log(receipt);
                                }); 
                            }
                    }
                }
            }
       
    }


    if(totalSupply < AppSalesLimit){
        return (
            <>
            <div className={styles.icb_container_zoom}>
                {(osChallenge)?(
                    <div className={styles.oschallenge_container}>
                        <div className={styles.oschallenge_close} onClick={closOsch}>X</div>
                        <div className={styles.oschallenge_title}>Don't list your VINYL for cheap!</div>
                        <div className={styles.oschallenge_subtitle}>Take part in our OS Price challenge instead and win even more ETH!</div>
                        <img src={osimg} alt="OS Challenge" className={styles.oschallenge_poster} />
                        <div className={styles.oschallenge_text}>The higher VINYL floor is by the time the public sale starts, the more successful will be VINYL and WBMC2.0 community and the more funds we have for our WBMC 2.0 DAO strategy!</div>
                    </div>
                ):""}
                {/*
                <div className={styles.icb_container_already_minted}>Minted: <span>{totalSupply}</span></div>
                */}


                {(isMetaMask && chainId !== '0x1') ? (<div className={styles.metamask_msg_error}>You should switch to Ethereum MainNet in Metamask!</div>) : 
                <>


                <form onSubmit={handleSubmit} className={styles.metamask_buy_form}>
                    {(transactionState === 2 || transactionState === 3 || transactionState === 4)?(<input type="submit" value="Mint for Vibe" className={styles.icb_container_button_disable} style={{fontSize: "1.1em"}}/>) : (<input type="submit"  value="Mint for Vibe" className={styles.icb_container_button} style={{fontSize: "1.1em"}} />)}
                </form>

                {(transactionState === 1) ? (<div className={styles.metamask_msg_error}>MetaMask: User denied transaction signature</div>) : ""}
                {(transactionState === 2) ? (<div className={styles.metamask_msg_success}>Transaction has been successfully sent to the blockchain.<br />1 token gonna appear in your wallet soon!</div>) : ""}
                {(transactionState === 3) ? (<div className={styles.metamask_msg_error}>You don't have enough Vibe!</div>) : ""}
                {(transactionState === 4) ? (<div className={styles.metamask_msg_success}>You need to CONFIRM the transaction in your METAMASK wallet!</div>) : ""}
                {(minted) ? (<div className={styles.metamask_msg_success}>Your vinyl is minted! Check your wallet</div>) : ""}

                <div className={styles.icb_container_buy_total}>Price: <span>{(tokenPrice).toFixed(0)}</span> $WBMCVIBE </div>
                <div className={styles.icb_container_buy_balance}>You have: {(tokenPrice > userBalance/rate)?(<><span className={styles.icb_redBalance}>{(userBalance/rate).toFixed(0)}</span> $WBMCVIBE</>):(<span>{(userBalance/rate).toFixed(0)} $WBMCVIBE</span>)}</div>


                </>
                }


                <div className={styles.icb_container_buy_gasPrice}>Current Gas Price: {(gasPrice < 140) ? (<span className={styles.greenGas}>{gasPrice} gwei</span>):((gasPrice > 250)? (<span className={styles.redGas}>{gasPrice} gwei</span>) : (<span>{gasPrice} gwei</span>) )}</div>

                {(userAddress !== 0) ? (<div className={styles.icb_container_buy_address}>Your address: {userAddress}</div>) : "" }
            </div>

            <div className={styles.info_more_mint}><p>If you need any help with minting please contact us<br /><a href={LinkTw} title="Twitter" target="_blank" rel="noreferrer nofollow noopener">Twitter</a>, <a href={LinkDs} title="Discord" target="_blank" rel="noreferrer nofollow noopener">Discord</a></p></div>

            <div className={styles.info_more_mint}>
                <h4>If you have WBMC tokens:</h4>
                <p style={{marginTop: 0}}>
                <Link to={'/mint-wbmc'} title='Mint for WBMC' className={styles.vinyl_button}>Free VINYL Claiming for Wannabes Holders</Link>&nbsp;&nbsp;</p>
                <h4>If you don't have WBMC tokens:</h4>
                <p style={{marginTop: 0}}>
                <Link to={'/mint-eth'} title='Minting with ETH' className={styles.vinyl_button}>Minting with ETH{(WhitelistOnly)?(<>: Only Whitelisted users</>):""}</Link>
                </p>
            </div>

            </>
        )
    } else {
        return (
            <h2>Sold out!</h2>
        )
    }
}

export default MintVibeForm
