import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";
import { Fade } from 'react-reveal';
import { useState } from "react";
import { Link } from 'react-router-dom';


  const Faq = React.memo(function MyComponent({salesLaunched, WhitelistOnly, LinkDs}) {

  const [openedTabs, setOpenedTabs] = useState([0]);


  function checkIsOpened(id) {
    if (openedTabs.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  function changeTabs(id) {

    if (checkIsOpened(id)) {
      let newArr = [...openedTabs]
      newArr.splice(openedTabs.indexOf(id), 1)
      setOpenedTabs(newArr);

    } else {
      let newArr = [...openedTabs]
      newArr.push(id);
      setOpenedTabs(newArr);
    }

  }


  function checkIsActive(id) {
    return openedTabs.includes(id)
  }

  return (
    <section id='faq' className={styles.section} >
     
      <div style={{ zIndex: 3, height: '100%' }} className="wrap">
      <div className={styles.inner}>
        <div className={styles.title}>
          <Headline tagtype='h2'>FAQ</Headline>
        </div>

        <div className={styles.faqitem}>
          <div onClick={() => changeTabs(1)} className={`${styles.faqtitle} ${checkIsActive(1) && styles.active}`}>
            WBMC presents Vinyl! What is the Vinyl drop?
          </div>
          <Fade left collapse when={checkIsActive(1)}>
            <div className={styles.faqtext}>
              <p>Join us for another journey into the WBMC Music Metaverse…</p>
              <p>A secret passage was found that leads to a room in the Wannabes Music Club… where a collection of Vinyl was discovered! This is a huge step in building the WBMC multiverse.</p>
              <p>Our Vinyl drop is a collection of audio-visual on-chain art NFTs created to give your Wannabe a companion, a meta-vinyl record to move & groove with as we flow into the metaverse!. All music, art and names were generated from the creative hearts & minds of the WBMC team.</p>
              <p>All WBMC holders who are actively involved in the community will receive Vinyl for free. Join the Wannabe fam and become a whale Vinyl collector today!</p>
            </div>
          </Fade>
        </div>

        <div className={styles.faqitem}>
          <div onClick={() => changeTabs(2)} className={`${styles.faqtitle} ${checkIsActive(2) && styles.active}`}>
            What is each Vinyl NFT?
          </div>
          <Fade left collapse when={checkIsActive(2)}>
            <div className={styles.faqtext}>
              <p>Each and every Vinyl in our limited collection is a piece of generative on-chain art packed into an ERC-721 token on the Ethereum blockchain.</p>
              <p>To make our Vinyl collection a unique innovation  NFT space, we added music and animation to each piece. That way, you can actually play the record and hear how your Vinyl was generated from stems contributed by the WBMC community! Every musician mentioned on Vinyl is a resident of WBMC Music Metaverse.</p>
              <p>Thanks to vector graphics, you can explore your Vinyl’s on-chain art in detail.The records come in different colors. You may collect a regular black Vinyl, or a rare one – which comes in silver, gold, or platinum.</p>
            </div>
          </Fade>
        </div>

        <div className={`${styles.faqitem}`}>
          <div onClick={() => changeTabs(3)} className={`${styles.faqtitle} ${checkIsActive(3) && styles.active}`}>
            Alright, sounds cool! So what is on-chain generative art?
          </div>
          <Fade left collapse when={checkIsActive(3)}>
            <div className={styles.faqtext}>
              <p>On-chain generative art is the hottest trend in NFTs right now! Why? It’s reliable. Most collections store metadata and art in a centralized manner. All the metadata and visual art for the Vinyl collection will be generated directly by the smart contract and immutably stored on the blockchain.</p>
              <p>Have you heard of Art Blocks? It is a pioneering & popular on-chain generative collection that has grown significantly in the single year since its inception. The average price of items from Art Blocks Factory collection on the secondary market is around 0.5 ETH. Art Blocks Curated are much, much (much!) more expensive though, a promising indicator for our multidimensional collection!</p>
              <p>As our WBMC concept is based on making dreams come true, we are glad to keep up with the modern trends, and experiment at the leading edge of NFT innovation. We do our best to score high on both creative and technical levels.</p>
              <p>Vinyl has it all – creative initiative, reliability, and it aligns with our WBMC agenda! We take good care of our Wannabe holders and wish to present our community with a new and valuable products and experiences.</p>
            </div>
          </Fade>
        </div>

        <div className={`${styles.faqitem}`}>
          <div onClick={() => changeTabs(4)} className={`${styles.faqtitle} ${checkIsActive(4) && styles.active}`}>
            Wow! I want a piece of generative art for myself now! Btw, does Vinyl have any utility as well?
          </div>
          <Fade left collapse when={checkIsActive(4)}>
            <div className={styles.faqtext}>
              <p>Sure! We wanted to incorporate more gamified fun, as well as one more opportunity to earn. That said, we are thrilled to announce our upcoming <b>Play to Earn</b> game, which is another growing trend in the NFT world. Does Axie Infinity ring a bell?</p>
            </div>
          </Fade>
        </div>

        <div className={`${styles.faqitem}`}>
          <div onClick={() => changeTabs(5)} className={`${styles.faqtitle} ${checkIsActive(5) && styles.active}`}>
            Play to earn will moon in 2022! Tell me more about the WBMC p2e game!
          </div>
          <Fade left collapse when={checkIsActive(5)}>
            <div className={styles.faqtext}>
              <p>So happy you asked! We looove the idea of making a p2e game!</p>
              <p>So! You’ll have to compete with other players for the audience’s excitement & cheering while you play a guitar simulator and collectible card game hybrid. The better you perform, the more excited they are! But what if your opponent performs better? This is where Vinyl comes in handy. Use Vinyl to boost your performance and win that battle! You earn $VIBE when you win, and the more battles you win, the higher is your overall rank. Top-rated performers are rewarded by ETH on a weekly basis!</p>
              <p>That’s where we start with that game. In the future, we will add a kind of “breeding”, so OG Wannabes will finally become <b>Superstars</b> and will be able to create new playable characters, by teaching other amateur musicians!</p>
              <p>We have plenty of ideas! Please wait for more announcements and a Whitepaper (exp. in December) to get the full perspective about the upcoming WBMC p2e game!</p>
              <p>Now you can earn and have fun at the same time, and we will still integrate some story-telling from our visual-novel game! That’s what makes our WBMC Music Metaverse so special to us –  we came to NFTs from the gaming industry and we sure do take it seriously. P2E is already big and gonna be huuuuuuuge very soon. We want to ride that wave together with our community! Let’s rock!</p>
            </div>
          </Fade>
        </div>

        <div className={`${styles.faqitem}`}>
          <div onClick={() => changeTabs(6)} className={`${styles.faqtitle} ${checkIsActive(6) && styles.active}`}>
            Sounds like you actually put some thought behind this. Ok, how do I obtain a Vinyl?
          </div>
          <Fade left collapse when={checkIsActive(6)}>
            <div className={styles.faqtext}>
              <p>If you hold Wannabes or enough $VIBE, you are already whitelisted. We designed this to bring unique value to our Wannabe holders, thank you for being in the community!  If not, you have a chance to qualify for the Whitelist and buy Vinyl for ETH. Whitelist terms will be announced later. Join our <a target='_blank' rel='noreferer nofollow noopener' href={LinkDs} title="Discord">Discord</a> to stay tuned!</p>
              <p>In case you do not have a Wannabe, $VIBE, or don’t get into the Whitelist – do not worry. We will launch a public sale open to everyone. However, no more than 500 Vinyls will make it to the public sale.</p>
            </div>
          </Fade>
        </div>

        <div className={`${styles.faqitem}`}>
          <div onClick={() => changeTabs(7)} className={`${styles.faqtitle} ${checkIsActive(7) && styles.active}`}>
          Great! Looks like getting a Wannabe is a good way to enjoy Vinyl as well! How many Vinyls can I get?
          </div>
          <Fade left collapse when={checkIsActive(7)}>
            <div className={styles.faqtext}>
            <p>1 Wannabe = 1 free Vinyl. (your Wannabe avatar acts as a mint pass for your Vinyl, you just pay gas.</p>
            <p>You can also buy 1 Vinyl for 5,000 $VIBE. You can buy as many Vinyls as you can afford with your $VIBE.</p>
            <p>The whitelist will enable you to buy Vinyl for 0.1 ETH. Whitelisting will be based on tiers, which will define how many Vinyls you can purchase on private sale.</p>
            <p>You can buy as many Vinyls as you want on public sale. However, we expect high demand for those few Vinyls presented there given the generative on-chain animation & music. So do not fully count on the public sale.</p>
            </div>
          </Fade>
        </div>

        <div className={`${styles.faqitem}`}>
          <div onClick={() => changeTabs(8)} className={`${styles.faqtitle} ${checkIsActive(8) && styles.active}`}>
          Wow, can’t wait! When can I get my Vinyl? Wen Vinyl?
          </div>
          <Fade left collapse when={checkIsActive(8)}>
            <div className={styles.faqtext}>
            <p>We hold a Metaverse party on November 19th where you can be the first to qualify for the Vinyl Whitelist! Our team is working hard so we can conquer the metaverse with our kickoff party! A stacked DJ line-up and our secret guest will ensure you have the time of your life! POAPs, giveaways and new encounters await you - including first peak at our Vinyls in our Music Metaverse! Ready to become a part of our metaverse community? Join our <a target='_blank' rel='noreferer nofollow noopener' href={LinkDs} title="Discord">Discord</a> and let us know how excited you are about our kickoff party!</p>
            <p>Vinyls will drop on November 26th. You will have 3 days for minting! After a private sale, we will hold a 24 hour public sale The rest of  the Vinyls will be burned. If Bansky can, why can’t we? This will make Vinyl exclusive and owned only by true music and NFT pioneers!</p>
            </div>
          </Fade>
        </div>
        </div>
      
      
        {(salesLaunched)?(<>
            {(WhitelistOnly)?(<><Link to='/mint' title="Mint" className={styles.mint_button}>Mint</Link></>):(<><Link to='/mint-eth' title="Mint" className={styles.mint_button}>Mint</Link></>)}
        </>):""}
      
      </div>

      
    </section>
  )
});


export default Faq;
