import { useEffect } from 'react';
import { Helmet } from "react-helmet";

import MintMInstall from '../sections/mint/MInstall';
import MintMConnect from '../sections/mint/MConnect';
import MintWbmcForm from '../sections/mint/MintWbmcForm';

const MintWbmc = ({AppName, AppUrl, LinkTw, LinkDs, setCurrentPage, salesLaunched, WhitelistOnly, isProvider, userAddress, connectProvider, SimpleContract, AppSalesLimit, OwnerContract, systemWbmcTotalSupply}) => {
    useEffect(() => {
		setCurrentPage('mint-wbmc');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{'Mint for Wannabes Holders - ' + AppName}</title>
                <meta name="description" content={'Mint for Wannabes Holders - ' + AppName} />
                <meta name="keywords" content={'Mint for Wannabes Holders - ' + AppName} />
                <meta property="og:title" content={'Mint for Wannabes Holders - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/mint-wbmc'} />
                <meta property="og:description" content={'Mint for Wannabes Holders - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/mint-wbmc'} />
            </Helmet>

            <div className="page-content">
                <div className="page-content-container">
                    <h1>Claiming for Wannabes Holders</h1>
                    {(salesLaunched) ? ((isProvider) ? ( (userAddress !== '0') ? (
                    <>
                        {(WhitelistOnly)?(
                        <>
                        <h3>Private sale</h3>
                        <MintWbmcForm SimpleContract ={SimpleContract} userAddress={userAddress} LinkTw={LinkTw} LinkDs={LinkDs} AppSalesLimit={AppSalesLimit} WhitelistOnly={WhitelistOnly} OwnerContract={OwnerContract} systemWbmcTotalSupply={systemWbmcTotalSupply}/>
                        </>
                        ):(<h3>Private sale is over</h3>)}
                    </>
                    ) : (<MintMConnect connectProvider={connectProvider} />) ) : (<MintMInstall />)) : (<h2>Minting have not started yet!</h2>) }
                </div>
            </div>
        </>
    )
}

export default MintWbmc
