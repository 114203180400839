import { useEffect } from 'react';
import { Helmet } from "react-helmet";

import MintMInstall from '../sections/mint/MInstall';
import MintMConnect from '../sections/mint/MConnect';
import WalletForm from '../sections/mint/WalletForm';

const Wallet = ({AppName, AppUrl, setCurrentPage, isProvider, userAddress, connectProvider, SimpleContract}) => {
    useEffect(() => {
		setCurrentPage('wallet');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{'Wallet - ' + AppName}</title>
                <meta name="description" content={'Wallet - ' + AppName} />
                <meta name="keywords" content={'Wallet - ' + AppName} />
                <meta property="og:title" content={'Wallet - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/wallet'} />
                <meta property="og:description" content={'Wallet - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/wallet'} />
            </Helmet>

            <div className="page-content">
                <div className="page-content-container">
                    <h1>Wallet</h1>
                    {(isProvider)?(
                        (userAddress !== '0') ? (
                        <>
                            <WalletForm SimpleContract ={SimpleContract} userAddress={userAddress} />
                        </>
                        ):(
                        <MintMConnect connectProvider={connectProvider} />)
                    ):(
                        <MintMInstall />
                    ) }
                </div>
            </div>
        </>
    )
}

export default Wallet
