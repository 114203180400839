import { Link } from 'react-router-dom';
import styles from './style.module.scss';

const Tokenslist = ({ListItems, ListItemAlt, ListWithLink}) => {
    const listContent = ListItems.map((ListItem) => {
        if(ListWithLink){
            return (
                <>
                /* ПЕРЕБРАТЬ! */
                /*
                <div>
                    <Link to={'/token/' + ListItem} key={ListItem}  title={ListItemAlt + ' ' + ListItem}>{'Vinyl ' + ListItem}</Link>
                </div>
                */
                </>
            )
        } else {
            return (
                <div key={ListItem} className={styles.tokens_list_item}>
                    <img src="https://wannabesmusic.club/images/vinyl-token.jpg" title={ListItemAlt + ListItem} alt={ListItemAlt + ' '+ ListItem} />
                    <div className={styles.tokens_list_item_title}>{ListItemAlt + ' '+ ListItem}</div>
                </div>
            )
        }
    });

    return (
        <div class={styles.tokens_list}>
            {listContent}
        </div> 
    )
}

export default Tokenslist
