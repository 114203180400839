import { Link } from 'react-router-dom';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HamburgerMenu from 'react-hamburger-menu';

const Header = React.memo(function MyComponent({ AppName, LinkTw, LinkDs, salesLaunched, WhitelistOnly}) {

  const Menu = () => (
    <>
      {(salesLaunched)?((WhitelistOnly)?(<li><Link to='/mint' title="Mint" onClick={closeMobileMenu}>Mint</Link></li>):(<li><Link to='/mint-eth' title="Mint" onClick={closeMobileMenu}>Mint</Link></li>)):""}
      <li><a href="/#about" onClick={closeMobileMenu}>about</a></li>
      <li><a href="/#faq" onClick={closeMobileMenu}>faq</a></li>
      <li><a href="/#team" onClick={closeMobileMenu}>team</a></li>
      {/* <li><a href="/tokens">explore</a></li>*/}
      <li><Link to='/wallet' title="Wallet" onClick={closeMobileMenu}>Wallet</Link></li>
      <li className={styles.icons}>
        <a target='_blank' rel='noreferer nofollow noopener' href={LinkDs} onClick={closeMobileMenu}>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.12 6.94601C22.696 4.99801 18.863 4.66801 18.701 4.65401C18.445 4.63201 18.202 4.77701 18.097 5.01101C18.093 5.01901 17.879 5.64001 17.672 6.23901C20.489 6.73201 22.403 7.82601 22.505 7.88601C22.983 8.16401 23.143 8.77701 22.864 9.25401C22.679 9.57201 22.344 9.75001 22 9.75001C21.829 9.75001 21.657 9.70701 21.499 9.61501C21.471 9.59801 18.663 8.00001 15.002 8.00001C11.34 8.00001 8.531 9.59901 8.503 9.61501C8.026 9.89201 7.414 9.72901 7.137 9.25101C6.86 8.77501 7.021 8.16401 7.497 7.88601C7.599 7.82601 9.52 6.72801 12.345 6.23601C12.127 5.63001 11.907 5.01901 11.903 5.01101C11.798 4.77601 11.555 4.62801 11.299 4.65401C11.137 4.66701 7.304 4.99701 4.848 6.97201C3.564 8.15801 1 15.092 1 21.087C1 21.193 1.027 21.296 1.08 21.388C2.851 24.498 7.679 25.312 8.779 25.347C8.786 25.348 8.792 25.348 8.798 25.348C8.992 25.348 9.175 25.255 9.29 25.098L10.48 23.486C7.87 22.857 6.49 21.868 6.407 21.807C5.963 21.48 5.867 20.854 6.194 20.409C6.52 19.966 7.144 19.868 7.588 20.193C7.625 20.217 10.172 22 15 22C19.847 22 22.387 20.21 22.412 20.192C22.856 19.87 23.482 19.967 23.807 20.413C24.131 20.857 24.037 21.479 23.595 21.805C23.512 21.866 22.139 22.853 19.535 23.482L20.71 25.097C20.825 25.255 21.008 25.347 21.202 25.347C21.209 25.347 21.215 25.347 21.221 25.346C22.322 25.311 27.15 24.497 28.92 21.387C28.973 21.295 29 21.192 29 21.086C29 15.092 26.436 8.15801 25.12 6.94601ZM11 19C9.895 19 9 17.881 9 16.5C9 15.119 9.895 14 11 14C12.105 14 13 15.119 13 16.5C13 17.881 12.105 19 11 19ZM19 19C17.895 19 17 17.881 17 16.5C17 15.119 17.895 14 19 14C20.105 14 21 15.119 21 16.5C21 17.881 20.105 19 19 19Z" fill="currentColor" />
          </svg>
        </a>
        <a target='_blank' rel='noreferer nofollow noopener' href={LinkTw} onClick={closeMobileMenu}><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.0306 7.23703C28.0467 7.67297 26.9887 7.96359 25.8727 8.10094C27.0075 7.42734 27.8803 6.35297 28.29 5.08359C27.2283 5.70609 26.0522 6.16312 24.8006 6.40406C23.8012 5.34469 22.3758 4.6875 20.7966 4.6875C17.7628 4.6875 15.3042 7.125 15.3042 10.1302C15.3042 10.5553 15.3539 10.9697 15.4477 11.3719C10.8839 11.1427 6.83625 8.97328 4.12828 5.68078C3.6525 6.48516 3.38484 7.42734 3.38484 8.42156C3.38484 10.3111 4.35234 11.9742 5.8275 12.9544C4.92703 12.9267 4.07953 12.6764 3.3375 12.2747C3.3375 12.2911 3.3375 12.3155 3.3375 12.3384C3.3375 14.9789 5.23125 17.1773 7.74141 17.678C7.2825 17.8036 6.79781 17.8734 6.29812 17.8734C5.94328 17.8734 5.59781 17.8331 5.26312 17.7731C5.96203 19.9308 7.98984 21.5081 10.3922 21.5545C8.5125 23.0119 6.14531 23.8847 3.57094 23.8847C3.12609 23.8847 2.69156 23.8594 2.26172 23.8073C4.69312 25.3472 7.58109 26.25 10.6809 26.25C20.782 26.25 26.3077 17.955 26.3077 10.7592C26.3077 10.5234 26.3002 10.2886 26.2889 10.0566C27.3675 9.29531 28.2966 8.33438 29.0306 7.23703Z" fill="currentColor" />
        </svg>
        </a>
      </li>
    </>
  )

  const [isMobMenuOpen, setisMobMenuOpen] = useState(false);

  function handleMobileMenu() {
    setisMobMenuOpen(!isMobMenuOpen);
  }

  function closeMobileMenu() {
    setisMobMenuOpen(false);
  }

  return (
    <div style={{ zIndex: 5 }} className="wrap expanded">
      <div className={styles.box}>
        <div className={styles.inner}>
          <Link to='/' title={AppName} className={styles.logo}>Vinyl</Link>
          <div className={styles.rightSide}>
            <div className={styles.gamburger}>
              <HamburgerMenu
              isOpen={isMobMenuOpen}
              menuClicked={()=>handleMobileMenu()}
              width={22}
              height={18}
              strokeWidth={2}
              rotate={0}
              color='white'
              borderRadius={0}
              animationDuration={0.5}/>
            </div>
            <ul className={styles.menu}>
            <Menu/>
            </ul>
          </div>
          {isMobMenuOpen &&
          <ul className={styles.mobmenu}>
          <Menu/>
          </ul>
          }
        </div>
      </div>
    </div>
  )
});

Header.propTypes = {
  AppName: PropTypes.string,
  LinkTw: PropTypes.string,
  LinkDs: PropTypes.string,
}

export default Header;