import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";

const Footer = React.memo(function MyComponent({ LinkTw, LinkDs, LinkWbmc, LinkMd, LinkOs, LinkContract, salesLaunched, WhitelistOnly}) {

  return (
    <section className='short'>
      {/* <div className='wrap'>
      <Headline tagtype='h2'>Whitepaper</Headline>

      <p>Need more technical details? Dive into our whitepaper.</p>
        <p><a href="#">Download whitepaper</a></p>
        </div> */}
      
      <div className={styles.footer}>
        <div className='wrap'>
          <ul className={styles.menu}>
            {(salesLaunched)?((WhitelistOnly)?(<li><Link to='/mint' title="Mint">Mint</Link></li>):(<li><Link to='/mint-eth' title="Mint">Mint</Link></li>)):""}

            <li><a target='_blank' rel='noreferer nofollow noopener' href={LinkDs} title="Discord">Discord</a></li>
            <li><a target='_blank' rel='noreferer nofollow noopener' href={LinkTw} title="Twitter">TWITTER</a></li>
            <li><a target='_blank' rel='noreferer nofollow noopener' href={LinkMd} title="Medium">Medium</a></li>
            <li><a target='_blank' rel='noreferer nofollow noopener' href={LinkWbmc} title="Wannabes Music Club">WBMC</a></li>
            <li><a target='_blank' rel='noreferer nofollow noopener' href={LinkOs} title="OpenSea">OpenSea</a></li>
            <li><a target='_blank' rel='noreferer nofollow noopener' href={LinkContract} title="Contract">Contract</a></li>

            <li><Link to='/wbmcvibe' title="Wbmc Vibe" >*</Link></li>
          </ul>
        </div>
      </div>
    </section>
  )
});

export default Footer;