import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";
import activeaudio from "../../../assets/img/activeaudio.png"
import blocklogo from "../../../assets/img/blocklogo.png"
import vlogo from "../../../assets/img/vlogo.png"
import tick from "../../../assets/img/tick.svg"
import rocket from "../../../assets/img/rocket.png"
// import music from "../../../assets/music/music.mp3"
import music from "../../../assets/music/music-2.mp3"
import React, { useRef, useEffect, useState } from 'react';
import { useIsVisible } from 'react-is-visible';
import Wave from "@foobar404/wave"

import track1 from "../../../assets/music/1_jazz.mp3"
import track1img from "../../../assets/music/1_jazz.svg"
import track2 from "../../../assets/music/2_hip-hop.mp3"
import track2img from "../../../assets/music/2_hip-hop.svg"
import track3 from "../../../assets/music/3_db.mp3"
import track3img from "../../../assets/music/3_db.svg"
import track4 from "../../../assets/music/4_house.mp3"
import track4img from "../../../assets/music/4_house.svg"
import track5 from "../../../assets/music/5_metal.mp3"
import track5img from "../../../assets/music/5_metal.svg"
import track6 from "../../../assets/music/6_rap.mp3"
import track6img from "../../../assets/music/6_rap.svg"

const data = [
  {
    id: 0,
    title: 'Plucky Monkeys',
    style: 'Jazz',
    musicfile: track1,
    imgfile: track1img,
  },
  {
    id: 1,
    title: 'The Bandits',
    style: 'Hip-hop',
    musicfile: track2,
    imgfile: track2img,
  },
  {
    id: 2,
    title: 'Electic Billie',
    style: 'Drum & bass',
    musicfile: track3,
    imgfile: track3img,
  },
  {
    id: 3,
    title: 'Heady Smiles',
    style: 'House',
    musicfile: track4,
    imgfile: track4img,
  },
  {
    id: 4,
    title: 'Stormy Leroy',
    style: 'Metal',
    musicfile: track5,
    imgfile: track5img,
  },
  {
    id: 5,
    title: 'Eternal Gregory',
    style: 'Rap',
    musicfile: track6,
    imgfile: track6img,
  }
]


const Audio = React.memo(function MyComponent() {


  const nodeRef = useRef()
  const audioref = useRef()


  const isVisible = useIsVisible(nodeRef)


  const [activeMusic, setActiveMusic] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  function switchTracks(id) {

    if (!audioref.current.paused) {
      setIsPlaying(true)
    } else {
      setIsPlaying(false)
    }

    setActiveMusic(id);

  }

  useEffect(() => {

    if (isPlaying) {
      audioref.current.play();
    }

  }, [activeMusic]);




  function printMusic() {

    function checkActive(id) {
      if (activeMusic === id) {
        return styles.activ;
      }
    }

    return (
      data.map((track) => (
        <div onClick={() => switchTracks(track.id)} key={track.id} className={`${styles.track} ${checkActive(track.id)}`}><img src={track.imgfile} alt={track.style} /></div>
      ))
    )

  }

  useEffect(() => {


      let wave = new Wave();
      wave.fromElement("audio_element_id","canvas_id",{type:"shockwave", colors: ["#0097FF", "#FFCC17", "#CEFF65"]});


  }, []);



  return (
    <section ref={nodeRef} className={styles.section} >
      <div className="wrap">
        <div className={styles.box}>


          <div className={styles.flex}>

            <div className={styles.title}>
              <Headline tagtype='h2'>Like <br /> ArtBlocks, <br /> but... <br /> <span>even better!</span></Headline>
            </div>


            <div className={styles.activevinyl}>
              <div className={styles.playlist}>
                {data && printMusic()}
              </div>
              <div className={styles.vinylimage}>
                <img src={data[activeMusic].imgfile} alt="" />
                <audio
                id='audio_element_id'
                  ref={audioref}
                  controls
                  src={isVisible ? data[activeMusic].musicfile : ''}
                  controlsList="nodownload"
                  >
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </div>
              <div className={styles.anim}>
                <div className={styles.inner}>
                  <canvas className={styles.canvas} id='canvas_id'></canvas>
                </div>
              </div>
            </div>


          </div>


          <table className={styles.table}>
            <tbody>
              <tr>
                <th>-</th>
                <th>Generative art</th>
                <th>Art is  on-chain </th>
                <th>Audio-visual art </th>
                <th>Animated art</th>
                <th>Has utility</th>
                <th>Avg price</th>
              </tr>
              <tr>
                <td className={styles.rowname}><img src={blocklogo} alt="" /> Artblock factory</td>
                <td><img src={tick} alt="" /></td>
                <td>partly</td>
                <td>some</td>
                <td>some</td>
                <td>no</td>
                <td>0.5 eth</td>
              </tr>
              <tr>
                <td className={styles.rowname}><img src={vlogo} alt="" /> Vinyl</td>
                <td><img src={tick} alt="" /></td>
                <td><img src={tick} alt="" /></td>
                <td><img src={tick} alt="" /></td>
                <td><img src={tick} alt="" /></td>
                <td><img src={tick} alt="" /></td>
                <td><img className={styles.rocket} src={rocket} alt="" /></td>
              </tr>
            </tbody>
          </table>

          <table className={styles.tableMob}>
            <tbody>
              <tr>
                <td width='1px'></td>
                <td className={styles.rowname}><img src={blocklogo} alt="" /> Artblock factory</td>
                <td className={styles.rowname}><img src={vlogo} alt="" /> Vinyl</td>
              </tr>
              <tr>
                <th>Generative art</th>
                <td><img src={tick} alt="" /></td>
                <td><img src={tick} alt="" /></td>
              </tr>
              <tr>
                <th>Art is  on-chain </th>
                <td>partly</td>
                <td><img src={tick} alt="" /></td>
              </tr>
              <tr>
                <th>Audio-visual art </th>
                <td>some</td>
                <td><img src={tick} alt="" /></td>
              </tr>
              <tr>
                <th>Animated art</th>
                <td>some</td>
                <td><img src={tick} alt="" /></td>
              </tr>
              <tr>
                <th>Has utility</th>
                <td>no</td>
                <td><img src={tick} alt="" /></td>
              </tr>
              <tr>
                <th>Avg price</th>
                <td>0.5 eth</td>
                <td><img className={styles.rocket} src={rocket} alt="" /></td>
              </tr>
            </tbody>
          </table>



        </div>
      </div>
    </section>
  )
});


export default Audio;
