import { useEffect } from 'react';
import { Helmet } from "react-helmet";

import MintMInstall from '../sections/mint/MInstall';
import MintMConnect from '../sections/mint/MConnect';
import MintEthForm from '../sections/mint/MintEthForm';

const MintEth = ({AppName, AppUrl, LinkTw, LinkDs, setCurrentPage, salesLaunched, WhitelistOnly, isProvider, userAddress, connectProvider, SimpleContract, web3, AppSalesLimit}) => {
    useEffect(() => {
		setCurrentPage('mint-eth');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{'Mint for ETH - ' + AppName}</title>
                <meta name="description" content={'Mint for ETH - ' + AppName} />
                <meta name="keywords" content={'Mint for ETH - ' + AppName} />
                <meta property="og:title" content={'Mint for ETH - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/mint-eth'} />
                <meta property="og:description" content={'Mint for ETH - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/mint-eth'} />
            </Helmet>

            <div className="page-content">
                <div className="page-content-container">
                    <h1>Minting with ETH</h1>
                    {(salesLaunched) ? ((isProvider) ? ( (userAddress !== '0') ? (
                    <>
                        {(WhitelistOnly)?(<h3>Only Whitelisted users</h3>):(<h3>Public Sale</h3>)}
                        <MintEthForm SimpleContract ={SimpleContract} userAddress={userAddress} web3={web3} LinkTw={LinkTw} LinkDs={LinkDs} AppSalesLimit={AppSalesLimit} WhitelistOnly={WhitelistOnly} />
                    </>
                    ) : (<MintMConnect connectProvider={connectProvider} />) ) : (<MintMInstall />)) : (<h2>Minting have not started yet!</h2>) }
                </div>
            </div>
        </>
    )
}

export default MintEth
