import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

const CheckWbmcForm = ({LinkTw, LinkDs, SimpleContract, SystemWbmcContract, SystemContract}) => {
    //const [chainId, setChainId] = useState('');
    //const [isMetaMask, setIsMetaMask] = useState(false);
    const [totalWBSupply, setTotalWBSupply] = useState(0);
    const [tokenId, setTokenId] = useState(1);
    //const [tokenState, setTokenState] = useState(false);
    const [msg, setMsg] = useState('');
    const [msgstate, setMsgstate] = useState(0);
    // Если tokenState = true - значит его склеймили

    useEffect(() => {
        //getChainId();
        getTotalWBSupply();
        //getTokenState();
    });

    /*
    const getChainId = async (e) => {
        if(SimpleContract.givenProvider){
            setChainId(SimpleContract.givenProvider.chainId);
            setIsMetaMask(SimpleContract.givenProvider.isMetaMask);
        }
    }
    */

    const getTotalWBSupply = async (e) => {
        if(SystemWbmcContract){
            const resultTotalWBSupply = await SystemWbmcContract.methods.totalSupply().call();
            if(resultTotalWBSupply){
                setTotalWBSupply(resultTotalWBSupply);
            }
        }
    }

    const getTokenState = async (tokenId) => {
        if(SystemContract){
            const resultTokenState = await SystemContract.methods.isClaimed(tokenId).call();
            if(resultTokenState){
                setMsg("Vinyl has already been claimed for Wannabe #"+tokenId);
                setMsgstate(1);
            } else {
                setMsg('You can claim Vinyl for Wannabe #'+tokenId);
                setMsgstate(2);
            }
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        getWbmcState();
    }

    const getWbmcState = async(e) => {
        setMsg('');
        setMsgstate(0);
        let token = parseInt(tokenId, 10);
        if(token < 1){
            token = 1;
        }
        if(token > totalWBSupply){
            token = totalWBSupply;  
        }
        setTokenId(token);
        getTokenState(token);
    }

    const checkID = (e) => {
        setMsg('');
        setMsgstate(0);
        var amount = e.target.value;
        amount = amount.replace(/[^\d]/g, '');
        amount = parseInt(amount, 10);
        if(amount > totalWBSupply){
            amount = totalWBSupply;
        }
        setTokenId(amount);
    }

return (
            <> 
                <div className={styles.icb_container_zoom}>
                    {/*
                    {(isMetaMask && chainId !== '0x1') ? (<div className={styles.metamask_msg_error}>You should switch to Ethereum MainNet in Metamask!</div>) : 
                    <>
                    */}
                        <p style={{marginBottom: "10px"}}>Entere Wannabe's ID:</p>
                        <form onSubmit={handleSubmit} style={{marginBottom: "15px"}}>
                            <input type="number" value={tokenId} name="tokenId" onChange={checkID}/>
                            <input type="submit" value="Check" style={{marginLeft:"10px"}}/>
                        </form>
                        {(msg !== '')?(<>
                            {(msgstate === 1)?(<><div style={{color: "red"}}>{msg}</div></>):(<>
                                {(msgstate === 2)?(<><div style={{color: "#00ff00"}}>{msg}</div></>):""}
                            </>)}
                        
                        
                        </>):""}
                    {/*
                    </>
                    }
                    */}
                </div>

                <div className={styles.info_more_mint}><p>If you need any help with minting please contact us<br /><a href={LinkTw} title="Twitter" target="_blank" rel="noreferrer nofollow noopener">Twitter</a>, <a href={LinkDs} title="Discord" target="_blank" rel="noreferrer nofollow noopener">Discord</a></p></div>
            </>
        )
}

export default CheckWbmcForm
