import React, { useEffect, useState } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import Web3 from 'web3';
import { createAlchemyWeb3 } from '@alch/alchemy-web3';

//Abis
import { abiStorage } from './abis';
import { abiVibeStorage } from './abisvibe';
import { abiOwnerStorage } from './abisowner';
import { abiWbmcStorage } from './abiswbmc';

// Pages
import Index from './components/pages/Index';
import Mint from './components/pages/Mint';
import MintEth from './components/pages/MintEth';
import MintVibe from './components/pages/MintVibe';
import MintWbmc from './components/pages/MintWbmc';
import MintWbmcById from './components/pages/MintWbmcById';
import Wallet from './components/pages/Wallet';
import CheckWbmc from './components/pages/CheckWbmc';
import Wbmcvibe from './components/pages/Wbmcvibe';
import NoMatch from './components/pages/NoMatch';

// Components
import Header from "./components/sections/header/Header";
import Footer from "./components/sections/footer/Footer";
import "./reset.scss";
import "./global.scss";

const AppName = 'Vinyl for music lovers';
const AppUrl = 'https://vinyl.wannabesmusic.club';
const AppSalesLimit = 1000;

const LinkTw = 'https://twitter.com/WannabesMusClub';
const LinkDs = 'https://discord.gg/mG7YCJArx9';
const LinkMd = 'https://medium.com/@wannabesmusicclub/vinyl-for-music-lovers-airdrop-for-holders-a9c9c6ad5f92';

const LinkOs = 'https://opensea.io/collection/vinyl-by-wbmc';
const LinkContract = 'https://etherscan.io/address/0x34f70AF73d742631faD0d3994Ac2b63D26AF6BEe';
const LinkWbmc = 'https://wannabesmusic.club';

// Main Contract Address
const contractAddr = '0x34f70AF73d742631faD0d3994Ac2b63D26AF6BEe'; // MainNet
// const contractAddr = '0x1e65A5643702Fa0A81aF44953331c0Ca59905DD8'; // TestNet

// WBMC Contract Address
const contractWbmcAddr = '0x402491a577373995fF3382e6da3c282cb0564902'; // MainNet
// const contractWbmcAddr = '0xcd2B83116aE59Bb0De97534684430836b1453907'; // TestNet

// Vibe Contract Address
const contractVibeAddr = '0x35C6c2B06C4137bf16FFED04De4346C8CEc8ee77'; // MainNet
// const contractVibeAddr = '0xC2464928A2607F1d313ec943DBB9Ff4abf183FFd'; // TestNet

// Tokens for owner Contract Address
const contractOwnerAddr = '0xfdfa6aCB9b04ea855E59C90146679daDEcDd9FE3'; // MainNet
// const contractOwnerAddr = '0x35C6c2B06C4137bf16FFED04De4346C8CEc8ee77'; // TestNet

// Alchemy key
const alchemyKey = 'https://eth-mainnet.alchemyapi.io/v2/2Oww-lsSnzFlLunjTHvO73flNBwcGKRR'; // MainNet
// const alchemyKey = 'https://eth-rinkeby.alchemyapi.io/v2/o9xyQ2yYONmW-c4SVqJ0RbfIDzGt-hE6'; // TestNet

// Connect for Metamask
const web3 = new Web3(Web3.givenProvider);
const SimpleContract = new web3.eth.Contract(abiStorage, contractAddr);

// const WbmcContract = new web3.eth.Contract(abiWbmcStorage, contractWbmcAddr);
const VibeContract = new web3.eth.Contract(abiVibeStorage, contractVibeAddr);
const OwnerContract = new web3.eth.Contract(abiOwnerStorage, contractOwnerAddr);

// Contract for System
const web3sytem = createAlchemyWeb3(alchemyKey)
const SystemContract = new web3sytem.eth.Contract(abiStorage, contractAddr);
const SystemWbmcContract = new web3sytem.eth.Contract(abiWbmcStorage, contractWbmcAddr);

const App = React.memo(function MyComponent() {
    const [currentPage, setCurrentPage] = useState('index');

    const [salesLaunched] = useState(true);
    const [WhitelistOnly] = useState(true);
    const [challengeLaunched] = useState(false);

    // For Web3
    const [isProvider, setIsProvider] = useState(false);
    const [userAddress, setUserAddress] = useState(0);

    // For system
    const [isSystemProvider, setIsSystemProvider] = useState(false);
    const [systemTotalSupply, setSystemTotalSupply] = useState(0);

    const [isSystemWbmcProvider, setIsSystemWbmcProvider] = useState(false);
    const [systemWbmcTotalSupply, setSystemWbmcTotalSupply] = useState(0);

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [loadingDelayExpired, setLoadingDelayExpired] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingDelayExpired(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    //WEB3
    useEffect(() => {
        isProviderConnected();
        getUserAddress();
        
        isSystemProviderConnected();
        getSystemTotalSupply();
    
        isSystemWbmcProviderConnected();
        getSystemWbmcTotalSupply();
      });
    
      const isProviderConnected = () => {
        if(SimpleContract.givenProvider){
            setIsProvider(true);
        } else {
            setIsProvider(false);
        }
      }
    
      const isSystemProviderConnected = () => {
        if(SystemContract){
            setIsSystemProvider(true);
        } else {
            setIsSystemProvider(false);
        }
      }
    
      const isSystemWbmcProviderConnected = () => {
        if(SystemWbmcContract){
            setIsSystemWbmcProvider(true);
        } else {
            setIsSystemWbmcProvider(false);
        }
      }
    
      const getUserAddress = async (e) => {
        if(isProvider){
          const accounts = await web3.eth.getAccounts();
          if(accounts[0]){
              setUserAddress(accounts[0]);
          } else {
              setUserAddress('0');
          }
        } else {
            setUserAddress('0');
        }
      }
    
      const getSystemTotalSupply = async (e) => {
        if(isSystemProvider){
            const resultSystemTotalSupply = await SystemContract.methods.totalSupply().call();
            if(resultSystemTotalSupply){
                setSystemTotalSupply(resultSystemTotalSupply);
            }
        } else {
            setSystemTotalSupply('0');
        }
      }
    
      const getSystemWbmcTotalSupply = async (e) => {
        if(isSystemWbmcProvider){
            const resultSystemWbmcTotalSupply = await SystemWbmcContract.methods.totalSupply().call();
            if(resultSystemWbmcTotalSupply){
                setSystemWbmcTotalSupply(resultSystemWbmcTotalSupply);
            }
        } else {
            setSystemWbmcTotalSupply('0');
        }
      }
    
      const connectProvider = () => {
        web3.eth.requestAccounts();
      }
    
      if(web3.givenProvider){
        web3.givenProvider.on("accountsChanged", (accounts) => {
            //console.log(accounts);
            isProviderConnected();
            getUserAddress(); 
        });
        web3.givenProvider.on("chainChanged", (chainId) => {
            //console.log(chainId);
            isProviderConnected();
            getUserAddress(); 
        });
      }


    return (
        <>
            <Header AppName={AppName} LinkTw={LinkTw} LinkDs={LinkDs} salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly} />
            <main>
                <div id="content">
                <Switch>
                    <Route path='/mint' exact strict><Mint AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} LinkOs={LinkOs} salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly} systemTotalSupply={systemTotalSupply} AppSalesLimit={AppSalesLimit} /></Route>

                    <Route path='/mint-eth' exact strict><MintEth AppName={AppName} AppUrl={AppUrl} LinkTw={LinkTw} LinkDs={LinkDs} setCurrentPage={setCurrentPage} salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly} isProvider={isProvider} userAddress={userAddress} connectProvider={connectProvider} SimpleContract={SimpleContract} web3={web3} AppSalesLimit={AppSalesLimit} /></Route>

                    <Route path='/mint-vibe' exact strict><MintVibe AppName={AppName} AppUrl={AppUrl} LinkTw={LinkTw} LinkDs={LinkDs} setCurrentPage={setCurrentPage} salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly} isProvider={isProvider} userAddress={userAddress} connectProvider={connectProvider} SimpleContract={SimpleContract} web3={web3} AppSalesLimit={AppSalesLimit} VibeContract={VibeContract} /></Route>

                    <Route path='/mint-wbmc' exact strict><MintWbmc AppName={AppName} AppUrl={AppUrl} LinkTw={LinkTw} LinkDs={LinkDs} setCurrentPage={setCurrentPage} salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly} isProvider={isProvider} userAddress={userAddress} connectProvider={connectProvider} SimpleContract={SimpleContract} web3={web3} AppSalesLimit={AppSalesLimit} OwnerContract={OwnerContract} systemWbmcTotalSupply={systemWbmcTotalSupply} /></Route>

                    <Route path='/mint-from-wannabe/:id' exact strict><MintWbmcById LinkTw={LinkTw} LinkDs={LinkDs} setCurrentPage={setCurrentPage} AppName={AppName} AppUrl={AppUrl} salesLaunched={salesLaunched} isProvider={isProvider} userAddress={userAddress} connectProvider={connectProvider} SimpleContract={SimpleContract} web3={web3} AppSalesLimit={AppSalesLimit} systemTotalSupply={systemTotalSupply} systemWbmcTotalSupply={systemWbmcTotalSupply} SystemWbmcContract={SystemWbmcContract} WhitelistOnly={WhitelistOnly} /></Route>

                    <Route path='/wallet' exact strict><Wallet AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} isProvider={isProvider} userAddress={userAddress} connectProvider={connectProvider} SimpleContract={SimpleContract} /></Route>

                    <Route path='/check-wbmc' exact strict><CheckWbmc LinkTw={LinkTw} LinkDs={LinkDs} setCurrentPage={setCurrentPage} AppName={AppName} AppUrl={AppUrl} salesLaunched={salesLaunched} isProvider={isProvider} userAddress={userAddress} connectProvider={connectProvider} SimpleContract={SimpleContract} web3={web3} AppSalesLimit={AppSalesLimit} systemTotalSupply={systemTotalSupply} systemWbmcTotalSupply={systemWbmcTotalSupply} SystemWbmcContract={SystemWbmcContract} WhitelistOnly={WhitelistOnly} SystemContract={SystemContract} /></Route>

                    <Route path='/wbmcvibe' exact strict><Wbmcvibe AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage}/></Route>

                    <Route path='/' exact ><Index AppName={AppName} AppUrl={AppUrl} LinkDs={LinkDs} scrollPosition={scrollPosition} loadingDelayExpired={loadingDelayExpired} salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly} /></Route>

                    <Route path="*"><NoMatch AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} /></Route>
                </Switch>
                </div>
            </main>
            {(scrollPosition > 200 || loadingDelayExpired) &&
                <Footer LinkTw={LinkTw} LinkDs={LinkDs} LinkWbmc={LinkWbmc} LinkMd={LinkMd} LinkOs={LinkOs} LinkContract={LinkContract} salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly}/>
            }
        </>);
});

export default App;
