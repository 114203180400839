import { useEffect } from 'react';
import { Helmet } from "react-helmet";

import MintMInstall from '../sections/mint/MInstall';
import MintMConnect from '../sections/mint/MConnect';
import CheckWbmcForm from '../sections/mint/CheckWbmcForm';

const CheckWbmc = ({AppName, AppUrl, LinkTw, LinkDs, setCurrentPage, salesLaunched, WhitelistOnly, isProvider, userAddress, connectProvider, SimpleContract, AppSalesLimit, systemWbmcTotalSupply, SystemWbmcContract, SystemContract}) => {

    useEffect(() => {
		setCurrentPage('check-wbmc');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{'Check Wannabe by ID - ' + AppName}</title>
                <meta name="description" content={'Check Wannabe by ID - ' + AppName} />
                <meta name="keywords" content={'Check Wannabe by ID - ' + AppName} />
                <meta property="og:title" content={'Check Wannabe by ID - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/check-wbmc'} />
                <meta property="og:description" content={'Check Wannabe by ID - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/check-wbmc'} />
            </Helmet>

            <div className="page-content">
                <div className="page-content-container">
                    <h1>Check Wannabe by ID</h1>
                        {(salesLaunched) ? (
                        <>
                            {(SystemWbmcContract)?(<><h3>Check if Vinyl is available for your Wannabe</h3>
                            <CheckWbmcForm  LinkTw={LinkTw} LinkDs={LinkDs} userAddress={userAddress} SimpleContract={SimpleContract} AppSalesLimit={AppSalesLimit} SystemWbmcContract={SystemWbmcContract} SystemContract={SystemContract} /></>):("")}     
                        </>
                        ) : (<h2>Minting have not started yet!</h2>) }
                </div>
            </div>
        </>
    )
}

export default CheckWbmc
