import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";



  const Team = React.memo(function MyComponent() {

  return (
    <section id='team' className={styles.section} >
      <div style={{ zIndex: 3, height: '100%' }} className="wrap">
        <div className={styles.title}>
          <Headline tagtype='h2'>Team</Headline>
        </div>

        <div className={styles.teambox}>

          <a target='_blank' rel='noreferer' href="https://twitter.com/CryptoArtBuddy" className={styles.member}>
            <div className={styles.name}> Eugene</div>
            <div className={styles.role}> Art and style</div>
          </a>

          <a target='_blank' rel='noreferer' href="https://twitter.com/filmakarov" className={styles.member}>
            <div className={styles.name}> Fil</div>
            <div className={styles.role}> Generative script, smart contract</div>
          </a>

          <a target='_blank' rel='noreferer' href="https://twitter.com/g_wbmc" className={styles.member}>
            <div className={styles.name}> George</div>
            <div className={styles.role}> Music</div>
          </a>

          <a target='_blank' rel='noreferer' href="https://twitter.com/KATALYZEx" className={styles.member}>
            <div className={styles.name}> Kat</div>
            <div className={styles.role}> Community creativity and artist relations</div>
          </a>

          <a target='_blank' rel='noreferer' href="https://twitter.com/MikeSpikeWhite" className={styles.member}>
            <div className={styles.name}> Max</div>
            <div className={styles.role}> Fullstack dev</div>
          </a>

          <a target='_blank' rel='noreferer' href="https://twitter.com/amy_nft" className={styles.member}>
            <div className={styles.name}> Nataly</div>
            <div className={styles.role}> Marketing</div>
          </a>

          <div className={styles.member}>
            <div className={styles.name}> Ruslan</div>
            <div className={styles.role}> Music</div>
          </div>

          <a target='_blank' rel='noreferer' href="https://twitter.com/epi_is_here" className={styles.member}>
            <div className={styles.name}> Daria</div>
            <div className={styles.role}> Metaverse events and artist relations</div>
          </a>

          <a target='_blank' rel='noreferer' href="https://twitter.com/FedorLinnik" className={styles.member}>
            <div className={styles.name}> Fedor</div>
            <div className={styles.role}> Business development</div>
          </a>

          <a target='_blank' rel='noreferer' href="https://twitter.com/marinaberwg" className={styles.member}>
            <div className={styles.name}> Marina</div>
            <div className={styles.role}> SMM and Community management</div>
          </a>

          <a target='_blank' rel='noreferer' href="https://twitter.com/nikitaa_nk" className={styles.member}>
            <div className={styles.name}> Nik</div>
            <div className={styles.role}> Cryptovoxels experience</div>
          </a>

        </div>





      </div>
    </section>
  )
});


export default Team;
