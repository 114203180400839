import styles from './style.module.scss';
import metamask from "../../../assets/img/metamask-logo.png";

const MConnect = ({connectProvider}) => {
    return (
        <div className={styles.icb_container}>
            <p>To get Vinyl you need to CONNECT your wallet!</p>
            
            <div className={styles.icb_container_button} onClick={() => connectProvider()}>Connect Metamask</div>

            <img src={metamask} alt="Connect Metamask" title="Connect Metamask" className={styles.icb_container_logo} />
        </div>
    )
}

export default MConnect
