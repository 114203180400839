import { useEffect } from 'react';
import { Helmet } from "react-helmet";

const Wbmcvibe = ({AppName, AppUrl, setCurrentPage}) => {
    useEffect(() => {
		setCurrentPage('wbmcvibe');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{AppName + ' - Wbmc Vibe'}</title>
                <meta name="description" content={AppName + ' - Wbmc Vibe'} />
                <meta name="keywords" content={AppName + ' - Wbmc Vibe'} />
                <meta property="og:title" content={AppName + ' - Wbmc Vibe'} />
                <meta property="og:url" content={AppUrl + '/wbmcvibe'} />
                <meta property="og:description" content={AppName + ' - Wbmc Vibe'} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl + '/wbmcvibe'} />
            </Helmet>

            <div className="page-content">
                <div className="page-content-container">
                    <h1>Wbmc Vibe</h1>
                    <p>Everywhere $VIBE = $WBMCVIBE</p>
                    <p>This is Wannabes Music Club utility ERC-20 token and every Wannabe mines 10 $WBMCVIBE per day.</p>
                    <p>$WBMCVIBE contract address is ​​0x35C6c2B06C4137bf16FFED04De4346C8CEc8ee77</p>
                </div>
            </div>
        </>
    )
}

export default Wbmcvibe