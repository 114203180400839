import { Helmet } from "react-helmet";

import Intro from "../sections/intro/Intro";
import Countdownbox from "../sections/countdown/Countdown";
import Audio from "../sections/audio/Audio";
import Creation from "../sections/creation/Creation";
import Multiverse from "../sections/multiverse/Multiverse";
import Holder from "../sections/holder/Holder";
import Faq from "../sections/faq/Faq";
import Team from "../sections/team/Team";
import Game from "../sections/game/Game";
import MailForm from "../sections/mailform/MailForm";

const Index = ({scrollPosition, loadingDelayExpired, AppName, AppUrl, LinkDs, salesLaunched, WhitelistOnly}) => {
    return (
        <>
            <Helmet>
                <title>{AppName + ' - NFT'}</title>
                <meta name="description" content={AppName} />
                <meta name="keywords" content={AppName} />
                <meta property="og:title" content={AppName} />
                <meta property="og:url" content={AppUrl} />
                <meta property="og:description" content={AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl} />
            </Helmet>

            <Intro />
            <Countdownbox salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly} />
            {(scrollPosition > 200 || loadingDelayExpired) &&
                <>
                    <Audio />
                    <Creation />
                    <Game />
                    <Multiverse />
                    <Holder salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly} />
                    <Faq salesLaunched={salesLaunched} WhitelistOnly={WhitelistOnly} LinkDs={LinkDs}/>
                    <Team />
                    <MailForm />
                </>
            }
        </>
    )
}

export default Index
