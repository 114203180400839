import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";
import Countdown, { zeroPad } from 'react-countdown';
import { Link } from 'react-router-dom';

  const Countdownbox = React.memo(function MyComponent({salesLaunched, WhitelistOnly}) {

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div>done!</div>;
    } else {
      return (
      <div className={styles.countarea} >
        <div><div className={styles.name}>days</div>{zeroPad(days)}</div>
        <div className={styles.sep}>:</div>
        <div><div className={styles.name}>hrs</div>{zeroPad(hours)}</div>
        <div className={styles.sep}>:</div>
        <div><div className={styles.name}>mins</div>{zeroPad(minutes)}</div>
        <div className={styles.sep}>:</div>
        <div><div className={styles.name}>secs</div>{zeroPad(seconds)}</div>
      </div>
      );
    }
  };


  return (
    <section className={styles.section} >
      <div className="wrap">

        {(salesLaunched)?(
        <>
            <div className={styles.box}>
                {(WhitelistOnly)?(<><Headline tagtype='h2'><span>Private</span> Minting<br />started</Headline></>):(<><Headline tagtype='h2'><span>Public</span> Minting<br />started</Headline></>)}
              
                
                <div className={styles.counterbox}>
                  <div className={styles.countarea}>
                      {(WhitelistOnly)?(<><Link to='/mint' title="Mint" className={styles.mint_button}>Mint</Link></>):(<><Link to='/mint-eth' title="Mint" className={styles.mint_button}>Mint</Link></>)}
                  </div>  
                </div>
            </div>
        </>
        ):(
        <>
            <div className={styles.box}>
              <Headline tagtype='h2'><span>Vinyls</span> Minting<br />  starts on NOV. 26th</Headline>
              <div className={styles.counterbox}>
                <Countdown
                  // date={Date.now() + 5000000}
                  date={Date.parse('26 Nov 2021 20:30:00 GMT')}
                  renderer={renderer}
                  daysInHours={false}
                  zeroPadTime={2}
                  zeroPadDays={2}
                />
              </div>
            </div>
        </>
        )}
        


      </div>
    </section>
  )
});


export default Countdownbox;
