import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import NoMatch from '../pages/NoMatch';
import MintMInstall from '../sections/mint/MInstall';
import MintMConnect from '../sections/mint/MConnect';
import MintWbmcByIdForm from '../sections/mint/MintWbmcByIdForm';

const MintWbmcById = ({AppName, AppUrl, LinkTw, LinkDs, setCurrentPage, salesLaunched, WhitelistOnly, isProvider, userAddress, connectProvider, SimpleContract, AppSalesLimit, systemWbmcTotalSupply, SystemWbmcContract}) => {
    let { id } = useParams();
    let int_id = parseInt(id);

    useEffect(() => {
		setCurrentPage('mint-from-wbmc');
	}, [setCurrentPage]);

    if(int_id > 0 && int_id <= systemWbmcTotalSupply){
        return (
            <>
                <Helmet>
                    <title>{'Claim Vinyl from Wannabe '+int_id+' - ' + AppName}</title>
                    <meta name="description" content={'Claim Vinyl from Wannabe '+int_id+' - ' + AppName} />
                    <meta name="keywords" content={'Claim Vinyl from Wannabe '+int_id+' - ' + AppName} />
                    <meta property="og:title" content={'Claim Vinyl from Wannabe '+int_id+' - ' + AppName} />
                    <meta property="og:url" content={AppUrl+'/mint-from-wannabe/'+int_id} />
                    <meta property="og:description" content={'Claim Vinyl from Wannabe '+int_id+' - ' + AppName} />
                    <meta property="og:image" content={AppUrl + '/share.jpg'} />
                    <link rel="canonical" href={AppUrl+'/mint-from-wannabe/'+int_id} />
                </Helmet>

                <div className="page-content">
                    <div className="page-content-container">
                        <h1>Claim Vinyl from Wannabe  #{int_id}</h1>
                        {(salesLaunched) ? ((isProvider) ? ( (userAddress !== '0') ? (
                        <>
                            {(WhitelistOnly)?(
                            <>
                                <h3>Private sale</h3>
                                <MintWbmcByIdForm  LinkTw={LinkTw} LinkDs={LinkDs} userAddress={userAddress} SimpleContract={SimpleContract} web3={web3} AppSalesLimit={AppSalesLimit} SystemWbmcContract={SystemWbmcContract} tokenId={int_id}/>
                            </>
                            ):(<h3>Private sale is over</h3>)}
                        </>
                        ) : (<MintMConnect connectProvider={connectProvider} />) ) : (<MintMInstall />)) : (<h2>Minting have not started yet!</h2>) }
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <><NoMatch setCurrentPage={setCurrentPage} /></>
        )
    }
}

export default MintWbmcById
