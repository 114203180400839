import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types'
import './NoMatch.css';

const NoMatch = ({AppName, AppUrl, setCurrentPage}) => {

    useEffect(() => {
		setCurrentPage('error');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{AppName}</title>
                <meta name="description" content={AppName} />
                <meta name="keywords" content={AppName} />
                <meta property="og:title" content={AppName} />
                <meta property="og:url" content={AppUrl} />
                <meta property="og:description" content={AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl} />
            </Helmet>

            <div className="page-content">
                <div className="page-content-container">
                    <div className="error-page">
                        <div className="error-page-title">404</div>
                        <div className="error-page-text">Page not found</div>
                    </div>
                </div>
            </div>
        </>
    )
}

NoMatch.propTypes = {
    AppName: PropTypes.string,
    AppUrl: PropTypes.string,
}

export default NoMatch