import styles from './style.module.scss';
import Headline from "../../common/headline/Headline";
import artist from "../../../assets/img/artist.png"




  const Multiverse = React.memo(function MyComponent() {

  return (
    <section className={styles.section} >
      <div style={{ zIndex: 3, height: '100%' }} className="wrap">
        <div className={styles.box}>
          <div className={styles.title}>
            <Headline tagtype='h2'><span>Vinyls</span> are <br /> a part of a <br /> Music-centered <br /> multiverse</Headline>
          </div>
          <div className={styles.content}>
            <img src={artist} alt="Vinyls multiverse" />
          </div>
        </div>
      </div>
    </section>
  )
});


export default Multiverse;
