import { useEffect } from 'react';
import { Helmet } from "react-helmet";

import MintMInstall from '../sections/mint/MInstall';
import MintMConnect from '../sections/mint/MConnect';
import MintVibeForm from '../sections/mint/MintVibeForm';

const MintVibe = ({AppName, AppUrl, LinkTw, LinkDs, setCurrentPage, salesLaunched, WhitelistOnly, isProvider, userAddress, connectProvider, SimpleContract, web3, AppSalesLimit, VibeContract}) => {
    useEffect(() => {
		setCurrentPage('mint-vibe');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{'Mint for Vibe - ' + AppName}</title>
                <meta name="description" content={'Mint for Vibe - ' + AppName} />
                <meta name="keywords" content={'Mint for Vibe - ' + AppName} />
                <meta property="og:title" content={'Mint for Vibe - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/mint-vibe'} />
                <meta property="og:description" content={'Mint for Vibe - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/mint-vibe'} />
            </Helmet>

            <div className="page-content">
                <div className="page-content-container">
                    <h1>Purchase VINYL with $VIBE</h1>
                    
                    {(salesLaunched) ? ((isProvider) ? ( (userAddress !== '0') ? (
                    <>
                        {(WhitelistOnly)?(
                        <>
                        <h3>Private sale</h3>
                        <MintVibeForm SimpleContract ={SimpleContract} userAddress={userAddress} web3={web3} LinkTw={LinkTw} LinkDs={LinkDs} AppSalesLimit={AppSalesLimit} VibeContract={VibeContract} WhitelistOnly={WhitelistOnly} />
                        </>
                        ):(<h3>Private sale is over</h3>)}
                    </>
                    ) : (<MintMConnect connectProvider={connectProvider} />) ) : (<MintMInstall />)) : (<h2>Minting have not started yet!</h2>) }
                </div>
            </div>
        </>
    )
}

export default MintVibe